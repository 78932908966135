import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentViewState.figmaUrl.android} codeUrl={checklists.componentViewState.codeUrl.android} checklists={checklists.componentViewState.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The most fundamental component for building a UI, View is a container that supports layout with flexbox, style, some touch handling, and accessibility controls. Extends react native view.`}</p>
    <p>{`Legion provides 4 different kinds of view, depending on how you want the styling to be handled.`}</p>
    <h3>{`View`}</h3>
    <p>{`Extends react native view, the default view by Legion`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { View } from '@my/ui';
`}</code></pre>
    <h3>{`Stack`}</h3>
    <p>{`An optional base for creating flex-based layouts. XStack, YStack and ZStack. They extend directly off the View`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/viewstate/View.png",
      "align": "center",
      "alt": "view react native"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react'
import { XStack, YStack, ZStack } from '@legion-crossplatform/ui'

export function StacksDemo() {
  return (
    <XStack maxWidth={250} padding="$2" alignSelf="center" space>
      <YStack
        flex={1}
        space="$2"
        borderWidth={2}
        borderColor="$color"
        borderRadius="$4"
        padding="$2"
      >
        <YStack backgroundColor="$color" borderRadius="$3" padding="$2" />
        <YStack backgroundColor="$color" borderRadius="$3" padding="$2" />
        <YStack backgroundColor="$color" borderRadius="$3" padding="$2" />
      </YStack>

      <XStack
        flex={1}
        space="$2"
        borderWidth={2}
        borderColor="$color"
        borderRadius="$4"
        padding="$2"
      >
        <YStack backgroundColor="$color" borderRadius="$3" padding="$2" />
        <YStack backgroundColor="$color" borderRadius="$3" padding="$2" />
        <YStack backgroundColor="$color" borderRadius="$3" padding="$2" />
      </XStack>

      <ZStack maxWidth={50} maxHeight={85} width={100} flex={1}>
        <YStack
          fullscreen
          borderRadius="$4"
          padding="$2"
          borderColor="$color"
          borderWidth={2}
        />
        <YStack
          borderColor="$color"
          fullscreen
          y={10}
          x={10}
          borderWidth={2}
          borderRadius="$4"
          padding="$2"
        />
        <YStack
          borderColor="$color"
          fullscreen
          y={20}
          x={20}
          borderWidth={2}
          borderRadius="$4"
          padding="$2"
        />
      </ZStack>
    </XStack>
  )
}

`}</code></pre>
    <h3>{`Example`}</h3>
    <p>{`An example using a wider array of style properties `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Text, XStack, YStack } from '@legion-crossplatform/ui'

export default () => (
  <XStack
    flex={1}
    flexWrap="wrap"
    backgroundColor="#fff"
    hoverStyle={{
      backgroundColor: 'red',
    }}
    // media query
    $gtSm={{
      flexDirection: 'column',
      flexWrap: 'nowrap',
    }}
  >
    <YStack gap="$3">
      <Text>Hello</Text>
      <Text>World</Text>
    </YStack>
  </XStack>
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Style props`}</h2>
    <p>{`Stack/Views are the building blocks of many components in legion-crossplatform. Their props are extended and can be used in other components.`}</p>
    <p>{`An example of how to use the style props:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`
import { View } from '@legion-crossplatform/ui'

const MyView = () => (
  <View
    backgroundColor="red"
    hoverStyle={{
      backgroundColor: 'green'
    }}
  />
)

`}</code></pre>
    <p>{`The full types style props can be read here :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`
import { ViewStyle as RNViewStyle } from 'react-native'

type BaseViewStyle = RNViewStyle & FlatTransformStyles & WebOnlyStyles

// these are accepted but only render on web:
type WebOnlyStyles =  {
  contain?: Properties['contain']
  touchAction?: Properties['touchAction']
  cursor?: Properties['cursor']
  outlineColor?: Properties['outlineColor']
  outlineOffset?: SpaceValue
  outlineStyle?: Properties['outlineStyle']
  outlineWidth?: SpaceValue
  userSelect?: Properties['userSelect']
  filter?: Properties['filter']
  backdropFilter?: Properties['backdropFilter']
  mixBlendMode?: Properties['mixBlendMode']
  backgroundImage?: Properties['backgroundImage']
  backgroundOrigin: Properties['backgroundOrigin'],
  backgroundPosition: Properties['backgroundPosition'],
  backgroundRepeat: Properties['backgroundRepeat'],
  backgroundSize: Properties['backgroundSize']
  backgroundColor: Properties['backgroundColor']
  backgroundClip: Properties['backgroundClip']
  backgroundBlendMode: Properties['backgroundBlendMode']
  backgroundAttachment: Properties['backgroundAttachment']
  background: Properties['background']
  clipPath: Properties['clipPath'],
  caretColor: Properties['caretColor']
  transformStyle: Properties['transformStyle'],
  mask: Properties['mask'],
  maskImage: Properties['maskImage'],
  textEmphasis: Properties['textEmphasis'],
  borderImage: Properties['borderImage'],
  float: Properties['float']
  content: Properties['content']
  overflowBlock: Properties['overflowBlock']
  overflowInline: Properties['overflowInline']
  maskBorder: Properties['maskBorder']
  maskBorderMode: Properties['maskBorderMode']
  maskBorderOutset: Properties['maskBorderOutset']
  maskBorderRepeat: Properties['maskBorderRepeat']
  maskBorderSlice: Properties['maskBorderSlice']
  maskBorderSource: Properties['maskBorderSource']
  maskBorderWidth: Properties['maskBorderWidth']
  maskClip: Properties['maskClip']
  maskComposite: Properties['maskComposite']
  maskMode: Properties['maskMode']
  maskOrigin: Properties['maskOrigin']
  maskPosition: Properties['maskPosition']
  maskRepeat: Properties['maskRepeat']
  maskSize: Properties['maskSize']
  maskType: Properties['maskType']
}

// these turn into the equivalent transform style props:
type FlatTransformStyles = {
  x?: number
  y?: number
  perspective?: number
  scale?: number
  scaleX?: number
  scaleY?: number
  skewX?: string
  skewY?: string
  matrix?: number[]
  rotate?: string
  rotateY?: string
  rotateX?: string
  rotateZ?: string
}

// add the pseudo and enter/exit style states
type WithStates = BaseViewStyle & {
  hoverStyle?: BaseViewStyle
  pressStyle?: BaseViewStyle
  focusStyle?: BaseViewStyle
  focusVisibleStyle?: BaseViewStyle
  disabledStyle?: BaseViewStyle
  enterStyle?: BaseViewStyle
  exitStyle?: BaseViewStyle
}

// final View style props
type ViewStyle = WithStates & {
  // add media queries
  $sm?: WithStates

  // add group queries
  $group-hover?: WithStates
  $group-focus?: WithStates
  $group-press?: WithStates

  // add group + container queries
  $group-sm-hover?: WithStates
  $group-sm-focus?: WithStates
  $group-sm-press?: WithStates

  // add named group queries
  $group-tabs?: WithStates
  $group-tabs-hover?: WithStates
  $group-tabs-focus?: WithStates
  $group-tabs-press?: WithStates

  // add named group + container queries
  $group-tabs-sm?: WithStates
  $group-tabs-sm-hover?: WithStates
  $group-tabs-sm-focus?: WithStates
  $group-tabs-sm-press?: WithStates

  // add theme queries
  $theme-light?: WithStates
  $theme-dark?: WithStates

  // add platform queries
  $platform-native?: WithStates
  $platform-ios?: WithStates
  $platform-android?: WithStates
  $platform-web?: WithStates
}

// Text style starts with this base but builds up the same:
type TextStyleBase = BaseViewStyle & {
  color?: string,
  fontFamily?: string,
  fontSize?: string,
  fontStyle?: string,
  fontWeight?: string,
  letterSpacing?: string,
  lineHeight?: string,
  textAlign?: string,
  textDecorationColor?: string,
  textDecorationLine?: string,
  textDecorationStyle?: string,
  textShadowColor?: string,
  textShadowOffset?: string,
  textShadowRadius?: string,
  textTransform?: string,
}
`}</code></pre>
    <h3>{`Style props order`}</h3>
    <p>{`Style props are sensitive to their order - a feature that without which would leave us with impossible styling challenges and awkward rules of inheritence we’re trying to get away from with CSS in JS`}</p>
    <div className="divi" />
    <h2>{`Props`}</h2>
    <p>{`Stack props accept every prop from react-native-web `}<inlineCode parentName="p">{`View`}</inlineCode>{` component. As well as the style props.
These props are extended into many components built from View or Stack.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`elevation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tokens.size`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fullscreen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sets position: absolute, top: 0, left: 0, right: 0, bottom: 0.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      